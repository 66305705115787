import './ai_tracking_form.css'
import React from "react";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import { AuthContext } from 'contexts/auth_context';
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { CartContext } from 'contexts/cart_context';
import AITrackingRow from './ai_tracking_row';
import { config } from 'Constants';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';


const AITrackingForm = (props) => {
    const addMealsFromCopy = React.useContext(CartContext).addMealsFromCopy;
    const [loading, setLoading] = React.useState(false)
    const [errorState, setErrorState] = React.useState(0)
    let [prompt, setPrompt] = React.useState('');
    const { currentUser } = React.useContext(AuthContext)
    let [foods, setFoods] = React.useState([])
    const favoriteFoodList = React.useContext(CartContext).favoriteFoodList
    const [buttonDisabled, setButtonDisabled] = React.useState(false)


    const handleChange = (e) => {
        setPrompt(e.target.value)
        setButtonDisabled(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        setErrorState(0)
        FirebaseAnalytics.logEvent({
            name: "started_tracked_with_ai",
            params: { AIprompt: prompt, currentUser: currentUser ? currentUser.uid : "null" }
        })
        setFoods([])
        axios.get('food-search/parseWithAI', { params: { prompt: prompt } }).then(response => {

            for (let foodItem of response.data) {
                foodItem.portionChosen = 1
                foodItem.food_id = uuidv4()
                foodItem.checked = true
            }
            setFoods(response.data)
            setLoading(false)
            setButtonDisabled(true)


        }).catch(error => {
            setErrorState(1)
            setFoods([])
            setLoading(false)
            FirebaseAnalytics.logEvent({
                name: "ai_tracking_error",
                params: { AIprompt: prompt, currentUser: currentUser ? currentUser.uid : "null" }
            })
        })




    }

    function handleAddToCalendar() {
        let meals = []
        for (let food of foods) {
            if (food.checked) {
                let mealObject = _.cloneDeep(config.emptyRecipe)
                mealObject = props.calendarCategory ? { ...mealObject, calendarCategory: props.calendarCategory } : { ...mealObject, calendarCategory: "breakfast" }
                mealObject = { ...mealObject, portion: food.portionChosen, name: food.name, description: "Made by AI - a serving is " + food.macros.portionInGrams + "g", areNutrientsManual: true,
            picture: `https://storage.googleapis.com/z-com-299512.appspot.com/App/${food.foodCategory}.png`
        }

                for (let mealNutrient of mealObject.nutrition.nutrients) {
                    if (mealNutrient.name === "Calories") {
                        mealNutrient['amount'] = Math.round(food.macros.calories * food.portionChosen)
                    }
                    if (mealNutrient.name === "Protein") {
                        mealNutrient['amount'] = Math.round(food.macros.protein * food.portionChosen)
                    }
                    if (mealNutrient.name === "Carbohydrates") {
                        mealNutrient['amount'] = Math.round(food.macros.carbohydrates * food.portionChosen)
                    }
                    if (mealNutrient.name === "Fat") {
                        mealNutrient['amount'] = Math.round(food.macros.fat * food.portionChosen)
                    }
                    if (mealNutrient.name === "Cholesterol") {
                        mealNutrient['amount'] = Math.round(food.macros.cholesterol * food.portionChosen)
                    }
                    if (mealNutrient.name === "Sugar") {
                        mealNutrient['amount'] = Math.round(food.macros.sugars * food.portionChosen)
                    }
                    if (mealNutrient.name === "Sodium") {
                        mealNutrient['amount'] = Math.round(food.macros.sodium * food.portionChosen)
                    }
                    if (mealNutrient.name === "Calcium") {
                        mealNutrient['amount'] = Math.round(food.macros.calcium * food.portionChosen)
                    }
                    if (mealNutrient.name === "Saturated Fat") {
                        mealNutrient['amount'] = Math.round(food.macros['saturated fat'] * food.portionChosen)
                    }
                    if (mealNutrient.name === "Fiber") {
                        mealNutrient['amount'] = Math.round(food.macros.fiber * food.portionChosen)
                    }
                }
                meals.push(mealObject)
            }
        }
        FirebaseAnalytics.logEvent({
            name: "ai_tracking_added_to_calendar",
            params: { AIprompt: prompt, currentUser: currentUser ? currentUser.uid : "null" }
        })

        addMealsFromCopy(meals, props.calendarDate, props.calendarCategory)
        props.handleExitClick()

    }

    let rows = []
    let allNotChecked = true
    for (let index in foods) {
        let food = foods[index]
        if (food.checked) {
            allNotChecked = false
        }
        let foodInFavorites = false;
        for (let favoriteFood of favoriteFoodList) {
            if (favoriteFood.food_id === food.food_id) {
                foodInFavorites = true
                break
            }
        }
        rows.push(<AITrackingRow food={food} foodList={foods} setFoodList={setFoods} index={index} foodInFavorites={foodInFavorites}
            handleExitClick={props.handleExitClick} calendarCategory={props.calendarCategory} calendarDate={props.calendarDate} />)
    }


    return (

        <>
            <div className="custom-meal-form-top-message">Describe your meal and use AI to estimate macros </div>

            <div >
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>



                    <div className="extract-recipe-dialog-form-line-item" >
                        <TextField className="ai-tracking-form-input" style={{ width: '90%' }} variant="outlined" disabled={loading} multiline required={true} name='URL' InputLabelProps={{ shrink: true }} value={prompt} onChange={handleChange} placeholder="e.g. one large grilled chicken thigh with half a cup of white rice and small side of bbq pinto beans"></TextField>

                    </div>


                    {!buttonDisabled && <div className="extract-recipe-dialog-form-submit-button">
                        <button type="submit" disabled={loading} className="order-ingredients-button-for-browser" style={{ padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold', width:'198px' }} value="Submit" >Estimate Macros<>{loading && <LoopIcon style={{ fontSize: '22px', margin: '-5px 0px -5px 5px', animation: 'spin 2s linear infinite' }} />}</></button>
                    </div>}

                </form>

                {foods.length === 0 ? <div style={{ fontSize: '10px', textAlign: 'center', color: 'grey', width: '305px', margin: '0px auto' }}>AI tracking provides a fast estimate of macronutrients but is not guaranteed to be 100% accurate. Note you can always adjust the macros once the items are in your calendar.</div>
                    : <>{rows}
                        <div className="extract-recipe-dialog-form-submit-button"><button disabled={loading || allNotChecked} className={!allNotChecked ? "order-ingredients-button-for-browser" : "order-ingredients-button-for-browser-disabled"} style={{ padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold', width:'198px' }} onClick={handleAddToCalendar} >Add to Calendar</button></div>

                    </>}

                {errorState === 1 && <div style={{ marginTop: "10px" }}><Alert severity="error"><>Error parsing food... Try again later</></Alert></div>}

            </div>

        </>
    )

}

export default AITrackingForm;