import React, { useMemo, useState } from 'react';
import WhatshotIcon from '@material-ui/icons/Whatshot'; // Fire icon from Material UI
import { UserContext } from 'contexts/user_context';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Define custom styles
const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        margin: theme.spacing(2),
        borderRadius: theme.shape.borderRadius * 2,
        width: '100%',
        maxWidth: 400, // Adjust the max width as needed
    },
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    streakDisplay: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '5px',
        cursor: 'pointer',
    },
    streakText: {
        marginLeft: '5px',
        fontWeight: 'bold',
        fontSize: '14px',
    },
}));

// Define the Transition
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StreakDisplay = () => {
    const classes = useStyles();
    const { userObject } = React.useContext(UserContext);
    const { mealDates } = userObject;

    const { streakLength, isActive } = useMemo(() => {
        if (!mealDates || mealDates.length === 0) {
            return { streakLength: 0, isActive: false };
        }

        // Get today's date at midnight
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Filter out future dates and convert to Date objects
        const pastOrTodayDates = mealDates
            .map(dateString => {
                const date = new Date(dateString);
                date.setHours(0, 0, 0, 0);
                return date;
            })
            .filter(date => date <= today);

        if (pastOrTodayDates.length === 0) {
            return { streakLength: 0, isActive: false };
        }

        // Sort the dates
        const sortedDates = pastOrTodayDates.sort((a, b) => a - b);

        let streak = 1;
        let maxStreak = 1;
        let lastTrackedDate = sortedDates[0];

        for (let i = 1; i < sortedDates.length; i++) {
            const prevDate = sortedDates[i - 1];
            const currentDate = sortedDates[i];

            const diffDays = (currentDate - prevDate) / (1000 * 60 * 60 * 24);

            if (diffDays === 1) {
                // Consecutive day
                streak++;
            } else if (diffDays > 1) {
                // Gap in streak
                streak = 1;
            }
            lastTrackedDate = currentDate;
            if (streak > maxStreak) {
                maxStreak = streak;
            }
        }

        // Check if the streak is active (includes today or yesterday)
        const daysSinceLastTrack = (today - lastTrackedDate) / (1000 * 60 * 60 * 24);

        const isActive = daysSinceLastTrack <= 1;

        return { streakLength: isActive ? streak : 0, isActive };
    }, [mealDates]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div>
            <div className={classes.streakDisplay} onClick={handleClickOpen}>
                <WhatshotIcon
                    style={{
                        color: isActive ? 'rgb(255, 128, 44)' : 'rgba(255,255,255,0.4)',
                    }}
                />
                <span
                    className={classes.streakText}
                    style={{
                        color: isActive ? 'white' : 'rgba(255,255,255,0.4)',
                    }}
                >
                    {isActive ? streakLength : 0} day{streakLength !== 1 ? 's' : ''}
                </span>
            </div>

            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="streak-dialog-title"
                PaperProps={{
                    classes: {
                        root: classes.dialogPaper,
                    },
                }}
            >
                <DialogTitle id="streak-dialog-title" > 
                    <div style={{ display: "flex", alignItems: 'center' }}><WhatshotIcon
                    style={{
                        color: 'rgb(255, 128, 44)', marginRight: '5px'
                    }} />Logging Streak
                </div></DialogTitle>

                <DialogContent className={classes.dialogContent}>
                        Your streak represents the number of consecutive days you've tracked your meals.
                        <br></br>
                        <br></br>
                        Remember - consistency is more important than perfection.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Got it
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default StreakDisplay;
