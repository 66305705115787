// basic_info_step.jsx
import React from 'react';
import {
    TextField,
    InputAdornment,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
    withStyles,
} from '@material-ui/core';
import { getDateMonthYear } from 'helpers/date_helpers';
import './basic_info_step.css';
import { UserContext } from 'contexts/user_context';

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(76, 184, 133)',
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgb(76, 184, 133)',
        },
    },
})(TextField);

const StyledFormControl = withStyles({
    root: {
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'rgb(76, 184, 133)',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgb(76, 184, 133)',
        },
    },
})(FormControl);

const BasicInfoStep = ({ formData, onUpdate, onNext }) => {
    const [errors, setErrors] = React.useState({});
    const getCurrentWeight = React.useContext(UserContext).getCurrentWeight

 

    const [currentWeight, setCurrentWeight] = React.useState(getCurrentWeight()[0] || '');
    const [weightUnit, setWeightUnit] = React.useState(getCurrentWeight()[1] || 'lbs');
    const [currentBodyFat, setCurrentBodyFat] = React.useState(formData.currentBodyFat ? parseFloat(formData.currentBodyFat) : '');

    const handleInputChange = (field, value) => {
        if (errors[field]) {
            setErrors(prev => ({ ...prev, [field]: '' }));
        }

        if (field === 'weightUnit') {
            setWeightUnit(value);
            const newWeight = convertWeight(currentWeight, weightUnit, value);
            setCurrentWeight(newWeight);
            const dateKey = getDateMonthYear(new Date());
            onUpdate({
                weight: {
                    ...formData.weight,
                    [dateKey]: {
                        value: newWeight,
                        unit: value
                    }
                }
            });
        } else if (field === 'currentWeight') {
            setCurrentWeight(value);
            const dateKey = getDateMonthYear(new Date());
            onUpdate({
                weight: {
                    ...formData.weight,
                    [dateKey]: {
                        value: parseFloat(value),
                        unit: weightUnit
                    }
                }
            });
        } else if (field === 'currentBodyFat') {
            setCurrentBodyFat(value);

            onUpdate({
                currentBodyFat: parseFloat(value)
            });

        } else {
            onUpdate({ [field]: value });
        }
    };

    const convertWeight = (weight, fromUnit, toUnit) => {
        if (!weight || fromUnit === toUnit) return weight;
        return fromUnit === 'lbs'
            ? Number((weight * 0.453592).toFixed(1))  // lbs to kg
            : Number((weight * 2.20462).toFixed(1));  // kg to lbs
    };

    const validateField = (field) => {
        const value = field === 'currentWeight' ? currentWeight :
            field === 'currentBodyFat' ? currentBodyFat :
                formData[field];
        switch (field) {
            case 'age':
                if (!value || value < 18 || value > 100) {
                    return 'Please enter a valid age between 18 and 100';
                }
                break;
            case 'currentWeight':
                const minWeight = weightUnit === 'lbs' ? 70 : 31.75;
                const maxWeight = weightUnit === 'lbs' ? 600 : 272.15;
                if (!value || value < minWeight || value > maxWeight) {
                    return `Please enter a valid weight between ${minWeight} and ${maxWeight} ${weightUnit}`;
                }
                break;
            case 'currentBodyFat':
                if (value) {
                    const minBF = formData.sex === 'male' ? 5 : 12;
                    if (value < minBF || value > 60) {
                        return `Body fat should be between ${minBF}% and 60%`;
                    }
                }
                break;
            default:
                break;
        }
        return '';
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.sex) newErrors.sex = 'Please select your gender';
        if (!formData.age) newErrors.age = 'Please enter your age';
        if (!currentWeight) newErrors.currentWeight = 'Please enter your current weight';

        if (formData.heightUnit === 'cm') {
            if (!formData.height || formData.height < 130 || formData.height > 230) {
                newErrors.height = 'Please enter a valid height between 130cm and 230cm';
            }
        } else {
            if (!formData.heightFeet || !formData.heightInches) {
                newErrors.height = 'Please enter your full height';
            } else {
                const totalInches = (parseInt(formData.heightFeet) * 12) + parseInt(formData.heightInches);
                if (totalInches < 51 || totalInches > 90) {
                    newErrors.height = 'Please enter a valid height between 4\'3" and 7\'6"';
                }
            }
        }

        ['currentWeight', 'currentBodyFat'].forEach(field => {
            const error = validateField(field);
            if (error) newErrors[field] = error;
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleHeightChange = (type, value) => {
        if (formData.heightUnit === 'cm') {
            handleInputChange('height', value);
        } else {
            handleInputChange(type === 'feet' ? 'heightFeet' : 'heightInches', value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Ensure all current values are saved before proceeding
            const dateKey = getDateMonthYear(new Date());
            const updates = {
                weight: {
                    ...formData.weight,
                    [dateKey]: {
                        value: parseFloat(currentWeight),
                        unit: weightUnit
                    }
                }
            };
            if (currentBodyFat !== '') {
                updates.currentBodyFat = parseFloat(currentBodyFat);
            }

            onUpdate(updates);
            onNext();
        }
    };

    return (
        <form onSubmit={handleSubmit} className="bt-basic-info-form">
            <div className="bt-basic-info-title">
                Tell us about
                <b style={{ color: 'rgb(76, 184, 133)' }}> yourself</b>
            </div>

            <div className="bt-basic-info-section">
                <StyledFormControl variant="outlined" fullWidth error={!!errors.sex}>
                    <InputLabel>Gender</InputLabel>
                    <Select
                        value={formData.sex}
                        onChange={(e) => handleInputChange('sex', e.target.value)}
                        label="Gender"
                    >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                    </Select>
                    {errors.sex && (
                        <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                            {errors.sex}
                        </Typography>
                    )}
                </StyledFormControl>
            </div>

            <div className="bt-basic-info-section">
                <StyledTextField
                    label="Age"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.age}
                    onChange={(e) => handleInputChange('age', e.target.value)}
                    error={!!errors.age}
                    helperText={errors.age}
                    inputProps={{
                        min: 18,
                        max: 100
                    }}
                />
            </div>

            <div className="bt-basic-info-section">
                {formData.heightUnit === 'cm' ? (
                    <StyledTextField
                        label="Height"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={formData.height}
                        onChange={(e) => handleHeightChange('cm', e.target.value)}
                        error={!!errors.height}
                        helperText={errors.height}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Select
                                        value={formData.heightUnit}
                                        onChange={(e) => handleInputChange('heightUnit', e.target.value)}
                                        disableUnderline
                                    >
                                        <MenuItem value="cm">cm</MenuItem>
                                        <MenuItem value="ft">ft/in</MenuItem>
                                    </Select>
                                </InputAdornment>
                            ),
                        }}
                    />
                ) : (
                    <div className="bt-basic-info-height-imperial">
                        <StyledTextField
                            label="Height (ft)"
                            type="number"
                            variant="outlined"
                            value={formData.heightFeet}
                            onChange={(e) => handleHeightChange('feet', e.target.value)}
                            error={!!errors.height}
                        />
                        <StyledTextField
                            label="Height (in)"
                            type="number"
                            variant="outlined"
                            value={formData.heightInches}
                            onChange={(e) => handleHeightChange('inches', e.target.value)}
                            error={!!errors.height}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Select
                                            value={formData.heightUnit}
                                            onChange={(e) => handleInputChange('heightUnit', e.target.value)}
                                            disableUnderline
                                        >
                                            <MenuItem value="cm">cm</MenuItem>
                                            <MenuItem value="ft">ft/in</MenuItem>
                                        </Select>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                )}
                {errors.height && (
                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                        {errors.height}
                    </Typography>
                )}
            </div>

            <div className="bt-basic-info-section">
                <StyledTextField
                    label="Current Weight"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={currentWeight}
                    onChange={(e) => handleInputChange('currentWeight', e.target.value)}
                    error={!!errors.currentWeight}
                    helperText={errors.currentWeight}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Select
                                    value={weightUnit}
                                    onChange={(e) => handleInputChange('weightUnit', e.target.value)}
                                    disableUnderline
                                >
                                    <MenuItem value="lbs">lbs</MenuItem>
                                    <MenuItem value="kg">kg</MenuItem>
                                </Select>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>

            <div className="bt-basic-info-section">
                <StyledTextField
                    label="Current Body Fat % (optional)"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={currentBodyFat}
                    onChange={(e) => handleInputChange('currentBodyFat', e.target.value)}
                    error={!!errors.currentBodyFat}
                    helperText={errors.currentBodyFat || 'This helps us provide more accurate recommendations'}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>

            <div className="bt-basic-info-buttons">
                <button type="submit" className="bt-basic-info-submit-button">
                    Next
                </button>
            </div>
        </form>
    );
};

export default BasicInfoStep;