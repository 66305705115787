// projection_calculations.js

export const generateGoalWarnings = ({
    currentWeight,
    goalWeight,
    currentBodyFat,
    goalBodyFat,
    activityLevel,
    cardioActivity,
    muscleBuildingActivity,
    dailyCalories,
    macroDistribution,
    sex,
    weightUnit
}) => {
    const warnings = [];
    const recommendations = [];

    // Convert weights to kg for calculations if needed
    const toKg = weightUnit === 'lbs' ? 0.453592 : 1;
    const currentWeightKg = currentWeight * toKg;
    const goalWeightKg = goalWeight * toKg;

    // Calculate target changes
    const weightChange = goalWeightKg - currentWeightKg;
    const isWeightLoss = weightChange < 0;
    const bodyFatChange = goalBodyFat ? goalBodyFat - currentBodyFat : null;
    const targetWeeklyChange = weightChange / 52; // Maximum 1 year plan

    // Basic calorie warnings
    const minCalories = sex === 'male' ? 1500 : 1200;
    if (dailyCalories < minCalories) {
        warnings.push(`Your daily calorie intake of ${dailyCalories} calories is too low and may be unsafe. Minimum recommended is ${minCalories} calories.`);
    }

    // Activity level analysis
    if (isWeightLoss && activityLevel <= 1.2) {
        recommendations.push("Increasing your daily activity level (e.g., walking more, taking stairs) will help create a calorie deficit without severely restricting food intake.");
    }

    // Cardio recommendations
    if (isWeightLoss && cardioActivity <= 1.06) {
        recommendations.push("Adding 2-3 cardio sessions per week can help you achieve your weight loss goal while maintaining muscle mass.");
    } else if (!isWeightLoss && cardioActivity >= 1.18 && bodyFatChange && bodyFatChange < 0) {
        recommendations.push("Consider reducing cardio frequency to 3-4 times per week to support muscle growth while losing body fat.");
    }

    // Muscle building activity recommendations
    if (muscleBuildingActivity <= 1.06) {
        if (bodyFatChange && bodyFatChange < 0) {
            recommendations.push("Increasing strength training to 3-4 times per week is crucial for maintaining muscle mass while losing body fat.");
        } else if (!isWeightLoss) {
            recommendations.push("To support muscle growth and achieve your goal weight, aim for 3-4 strength training sessions per week.");
        }
    }

    // Protein intake analysis
    const proteinPercentage = macroDistribution.protein;
    const dailyProteinGrams = (dailyCalories * (proteinPercentage / 100)) / 4;
    const proteinPerKg = dailyProteinGrams / currentWeightKg;

    if (proteinPercentage < 30 && (isWeightLoss || (bodyFatChange && bodyFatChange < 0))) {
        recommendations.push(`Increasing protein intake to at least 30% of total calories will help preserve muscle mass during fat loss.`);
    } else if (proteinPercentage < 30 && !isWeightLoss) {
        recommendations.push(`To support muscle growth, consider increasing protein intake to 30% of total calories.`);
    }

    // Goal feasibility warnings
    const maxWeeklyLoss = 0.9; // kg
    const maxWeeklyGain = 0.5; // kg

    if (Math.abs(targetWeeklyChange) > (isWeightLoss ? maxWeeklyLoss : maxWeeklyGain)) {
        const timeNeeded = Math.ceil(Math.abs(weightChange) / (isWeightLoss ? maxWeeklyLoss : maxWeeklyGain) / 52);
        warnings.push(`Your weight goal may take ${timeNeeded} years to achieve safely. Consider setting an intermediate goal.`);
    }

    // Body fat specific recommendations
    if (bodyFatChange) {
        const minBodyFat = sex === 'male' ? 8 : 15;
        if (goalBodyFat < minBodyFat) {
            warnings.push(`A body fat percentage below ${minBodyFat}% may be unsustainable and potentially unsafe.`);
        }

        if (bodyFatChange < 0 && muscleBuildingActivity <= 1.06 && cardioActivity <= 1.06) {
            warnings.push("Your current activity level may not be sufficient to achieve your body fat goal while maintaining muscle mass.");
        }
    }

    return {
        warnings: warnings,
        recommendations: recommendations.join('\n\n')
    };
};

export const calculateProjections = ({
    currentWeight,
    goalWeight,
    currentBodyFat,
    goalBodyFat,
    dailyCalories,
    macroDistribution,
    activityLevel,
    cardioActivity,
    muscleBuildingActivity,
    userObject,
}) => {
    let warning = '';
    let recommendations = '';
    let dataPoints = [];
    let goalDate = '';
    let bodyFatGoalDate = '';
    let weightGoalMetWeek = null;
    let bodyFatGoalMetWeek = null;
    let estimatedBodyFat = false;

    const calculateFatLossRatio = (currentBodyFatPct, sex, proteinPerKg, muscleBuildingActivity) => {
        const minBodyFatPct = sex === 'female' ? 15 : 8;
        const maxFatLossRatio = 0.85;
        const minFatLossRatio = 0.70;
    
        // Linear base calculation with smoother progression
        const distanceFromMin = Math.max(0, currentBodyFatPct - minBodyFatPct);
        
        // More responsive to small deficits when further from minimum
        let fatLossRatio = minFatLossRatio + (maxFatLossRatio - minFatLossRatio) * 
            (1 - Math.exp(-0.35 * distanceFromMin));
    
        // Stronger impact from protein and training
        const proteinRequirement = 2.2;
        const proteinFactor = Math.min(1, proteinPerKg / proteinRequirement);
        fatLossRatio *= (0.85 + 0.15 * proteinFactor);
    
        // Enhanced training impact
        if (muscleBuildingActivity > 1.0) {
            const trainingBonus = (muscleBuildingActivity - 1) * 0.25;
            fatLossRatio = Math.min(maxFatLossRatio, fatLossRatio + trainingBonus);
        }
    
        return fatLossRatio;
    };
    
    const calculateMetabolicAdaptation = (currentBodyFatPct, sex, weeklyCalorieBalance, currentAdaptation) => {
        const minBodyFatPct = sex === 'female' ? 15 : 8;
        
        // More gradual adaptation curve
        const distanceFromMin = Math.max(0, currentBodyFatPct - minBodyFatPct);
        const baseAdaptationRate = sex === 'female' ? 0.002 : 0.0015;
        
        // Less aggressive minimum adaptation
        const minAdaptation = sex === 'female' ? 
            0.89 - (0.03 * Math.exp(-0.25 * distanceFromMin)) : 
            0.91 - (0.03 * Math.exp(-0.25 * distanceFromMin));
    
        if (weeklyCalorieBalance < 0) {
            // Slower adaptation rate
            const adaptationRate = baseAdaptationRate * (1 + 0.2 * Math.exp(-0.25 * distanceFromMin));
            return Math.max(minAdaptation, currentAdaptation - adaptationRate);
        } else {
            // Quicker recovery when in surplus
            const recoveryRate = 0.01 * (1 + 0.15 * distanceFromMin);
            return Math.min(1.0, currentAdaptation + recoveryRate);
        }
    };

    // Estimate body fat if not provided
    if (!currentBodyFat) {
        const sex = userObject.sex || 'male';
        const age = userObject.age || 30;
        const heightCm = userObject.heightUnit === 'cm' ?
            userObject.height :
            ((parseFloat(userObject.heightFeet || 0) * 12) + parseFloat(userObject.heightInches || 0)) * 2.54;
        const weightKg = userObject.weightUnit === 'kg' ?
            currentWeight :
            currentWeight * 0.453592;
        const bmi = weightKg / Math.pow(heightCm / 100, 2);

        currentBodyFat = sex === 'male' ?
            Math.min(40, Math.max(10, (bmi - 5 + (age > 40 ? 3 : 0)))) :
            Math.min(45, Math.max(15, (bmi + (age > 40 ? 3 : 0))));

        estimatedBodyFat = true;
        warning = `Body fat percentage was estimated based on your height, weight, age, and sex. Estimated starting body fat: ${currentBodyFat.toFixed(1)}%.`;
    }

    // Check for necessary inputs
    if (!currentWeight || !goalWeight || !dailyCalories) {
        warning = 'Please enter your current weight, goal weight, and daily calories.';
        return { dataPoints, goalDate, warning, recommendations };
    }

    // Generate warnings and recommendations
    const { warnings, recommendations: recs } = generateGoalWarnings({
        currentWeight,
        goalWeight,
        currentBodyFat,
        goalBodyFat,
        activityLevel,
        cardioActivity,
        muscleBuildingActivity,
        dailyCalories,
        macroDistribution,
        sex: userObject.sex,
        weightUnit: userObject.weightUnit || 'lbs'
    });

    const allWarnings = warning ? [warning, ...warnings] : warnings;
    recommendations = recs;

    // Convert units if necessary
    const weightUnit = userObject.weightUnit || 'lbs';
    const heightUnit = userObject.heightUnit || 'cm';

    const currentWeightKg = weightUnit === 'kg' ? currentWeight : currentWeight * 0.453592;
    const goalWeightKg = weightUnit === 'kg' ? goalWeight : goalWeight * 0.453592;

    let heightCm;
    if (heightUnit === 'cm') {
        heightCm = userObject.height;
    } else {
        heightCm = ((parseFloat(userObject.heightFeet || 0) * 12) + parseFloat(userObject.heightInches || 0)) * 2.54;
    }

    // Biological constraints
    const sex = userObject.sex || 'male';
    const minBodyFatPercentage = sex === 'male' ? 8 : 15;
    const proteinRequirement = 2.2;

    // Calculate protein intake
    const proteinPercentage = macroDistribution.protein / 100;
    const proteinGramsPerDay = (dailyCalories * proteinPercentage) / 4;
    const proteinPerKg = proteinGramsPerDay / currentWeightKg;

    // BMR calculation using Mifflin-St Jeor
    const age = userObject.age || 30;
    let BMR;
    if (sex === 'male') {
        BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age + 5;
    } else {
        BMR = 10 * currentWeightKg + 6.25 * heightCm - 5 * age - 161;
    }

    // Initialize tracking variables
    let week = 0;
    let date = new Date();
    const maxWeeks = 52;
    let totalWeightKg = currentWeightKg;
    let fatMassKg = (currentWeightKg * currentBodyFat) / 100;
    let leanMassKg = currentWeightKg - fatMassKg;
    let metabolicAdaptation = 1.0;

    while (week < maxWeeks) {
        // More conservative TDEE calculation
        let TDEE = BMR * (1 + (activityLevel - 1) * 0.7); // Dampened activity multiplier

        // Weight-scaled activity impacts
        const cardioIntensity = cardioActivity - 1;
        const cardioImpact = cardioIntensity * totalWeightKg * 2.5 * 7;
        TDEE += cardioImpact;

        const resistanceIntensity = muscleBuildingActivity - 1;
        const resistanceImpact = resistanceIntensity * totalWeightKg * 2.5 * 7;
        TDEE += resistanceImpact;

        // TEF calculation
        const carbsPercentage = macroDistribution.carbs / 100;
        const fatPercentage = macroDistribution.fat / 100;
        let dailyProteinGrams = (dailyCalories * proteinPercentage) / 4;
        let dailyCarbsGrams = (dailyCalories * carbsPercentage) / 4;
        let dailyFatGrams = (dailyCalories * fatPercentage) / 9;
        let TEF = dailyProteinGrams * 4 * 0.2 + dailyCarbsGrams * 4 * 0.06 + dailyFatGrams * 9 * 0.02;
        TDEE += TEF;

        // Apply metabolic adaptation
        TDEE *= metabolicAdaptation;


        // Calculate energy balance
        const calorieBalance = dailyCalories - TDEE;
        const weeklyCalorieBalance = calorieBalance * 7;
        const weeklyWeightChangeKg = weeklyCalorieBalance / 7700;
        let currentBodyFatPct = (fatMassKg / totalWeightKg) * 100;

        let fatLossRatio = calculateFatLossRatio(
            currentBodyFatPct,
            sex,
            proteinPerKg,
            muscleBuildingActivity
        );
        // Adjust ratio based on protein intake
        if (proteinPerKg < proteinRequirement) {
            fatLossRatio *= (0.8 + (0.2 * (proteinPerKg / proteinRequirement)));
        }

        // Adjust ratio based on resistance training
        if (muscleBuildingActivity > 1.0) {
            fatLossRatio += (muscleBuildingActivity - 1) * 0.10;
        }

        // Deficit impact
        const deficitPercentage = Math.abs(weeklyCalorieBalance) / (TDEE * 7);
        if (deficitPercentage > 0.15) {
            fatLossRatio *= (1 - (deficitPercentage - 0.15) * 0.75);
        }

        // Calculate composition changes
        let fatLoss, muscleLoss;
        if (weeklyCalorieBalance < 0) {
            if (currentBodyFatPct <= minBodyFatPercentage) {
                fatLoss = 0;
                muscleLoss = weeklyWeightChangeKg;
            } else {
                fatLoss = weeklyWeightChangeKg * fatLossRatio;
                muscleLoss = weeklyWeightChangeKg * (1 - fatLossRatio);
            }
        } else {
            // Weight gain composition
            fatLoss = weeklyWeightChangeKg * 0.3;
            muscleLoss = weeklyWeightChangeKg * 0.7;
        }

        // Update masses
        fatMassKg += fatLoss;
        leanMassKg += muscleLoss;

        metabolicAdaptation = calculateMetabolicAdaptation(
            currentBodyFatPct,
            sex,
            weeklyCalorieBalance,
            metabolicAdaptation
        );
        // Enforce minimum body fat
        if (currentBodyFatPct < minBodyFatPercentage) {
            fatMassKg = (totalWeightKg * minBodyFatPercentage) / 100;
        }

        // Update total weight
        totalWeightKg = fatMassKg + leanMassKg;

        // Record data point
        const dataPoint = {
            date: date.getTime(),
            weight: weightUnit === 'kg' ? parseFloat(totalWeightKg.toFixed(2)) : parseFloat((totalWeightKg / 0.453592).toFixed(2)),
            bodyFat: parseFloat(((fatMassKg / totalWeightKg) * 100).toFixed(2))
        };
    

        dataPoints.push(dataPoint);

        // Check if goals are met
        if (weightGoalMetWeek === null) {
            if ((goalWeightKg < currentWeightKg && totalWeightKg <= goalWeightKg) ||
                (goalWeightKg > currentWeightKg && totalWeightKg >= goalWeightKg)) {
                weightGoalMetWeek = week;
            }
        }

        if (goalBodyFat && bodyFatGoalMetWeek === null) {
            if ((goalBodyFat < currentBodyFat && currentBodyFatPct <= goalBodyFat) ||
                (goalBodyFat > currentBodyFat && currentBodyFatPct >= goalBodyFat)) {
                bodyFatGoalMetWeek = week;
            }
        }
        
        // Update for next week
        week++;
        date.setDate(date.getDate() + 7);

        // Recalculate BMR for new weight
        if (sex === 'male') {
            BMR = 10 * totalWeightKg + 6.25 * heightCm - 5 * age + 5;
        } else {
            BMR = 10 * totalWeightKg + 6.25 * heightCm - 5 * age - 161;
        }
    }

    const finalDataPoint = dataPoints[dataPoints.length - 1];

    return {
        dataPoints,
        goalDate: weightGoalMetWeek !== null ? new Date(date.getTime()).toLocaleDateString() : null,
        bodyFatGoalDate: bodyFatGoalMetWeek !== null ? new Date(date.getTime()).toLocaleDateString() : null,
        warning: allWarnings,
        recommendations,
        weightGoalMetWeek,
        bodyFatGoalMetWeek,
        finalWeight: finalDataPoint.weight,
        finalBodyFat: finalDataPoint.bodyFat,
        estimatedBodyFat
    };
};