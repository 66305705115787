import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth_context';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { groupDataByWeek, aggregateWeeklyData, getISOWeek } from 'helpers/data_helpers';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Brush,
} from 'recharts';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const getDateOfISOWeek = (isoWeekString) => {
    const [yearStr, weekStr] = isoWeekString.split('-W');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);

    // Get first day of year
    const januaryFirst = new Date(year, 0, 1);
    
    // Get Thursday of week 1
    const firstThursday = new Date(year, 0, 1 + ((11 - januaryFirst.getDay()) % 7));
    
    // Get Monday of the requested week
    const weekStart = new Date(firstThursday);
    weekStart.setDate(firstThursday.getDate() + (week - 1) * 7 - 3);
    
    return weekStart;
};

const CaloriesOverTimeGraph = () => {
    const { currentUser } = useContext(AuthContext);
    const [weeklyData, setWeeklyData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWeeklyData = async () => {
            try {
                const db = getFirestore();
                const calendarMealCollectionRef = collection(
                    db,
                    'users',
                    currentUser.uid,
                    'calendarMeals'
                );

                const timeFrame = new Date();
                timeFrame.setDate(timeFrame.getDate() - 180);

                const mealsQuery = query(
                    calendarMealCollectionRef,
                    where('calendarDate', '>', timeFrame)
                );

                const querySnapshot = await getDocs(mealsQuery);

                let data = [];

                querySnapshot.forEach((doc) => {
                    const tempDoc = doc.data();
                    if (tempDoc.calendarDate) {
                        tempDoc.calendarDate = new Date(
                            tempDoc.calendarDate.seconds * 1000
                        );

                        // Get total calories and macronutrient grams
                        const totalCalories = parseFloat(getMealNutrientAmount(tempDoc, 'Calories')) || 0;
                        const proteinGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Protein')) || 0;
                        const carbsGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Carbohydrates')) || 0;
                        const fatGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Fat')) || 0;

                        data.push({
                            date: tempDoc.calendarDate,
                            totalCalories,
                            proteinGrams,
                            carbsGrams,
                            fatGrams
                        });
                    }
                });

                const groupedData = groupDataByWeek(data);
                let aggregatedData = aggregateWeeklyData(groupedData);

                // Log to Firebase Analytics
                FirebaseAnalytics.logEvent({
                    name: 'saw_macros_graph',
                });

                // Modify week labels
                aggregatedData = aggregatedData.map((week) => {
                    const weekStartDate = getDateOfISOWeek(week.week);
                    const options = { month: 'numeric', day: 'numeric' };
                    const formattedDate = weekStartDate.toLocaleDateString(undefined, options);
                    return {
                        ...week,
                        weekLabel: `Week of ${formattedDate}`,
                    };
                });

                setWeeklyData(aggregatedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching weekly data:', error);
                setLoading(false);
            }
        };

        fetchWeeklyData();
    }, [currentUser.uid]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: '#fff',
                    padding: '10px',
                    border: '1px solid #ccc'
                }}>
                    <p className="label">
                        <strong>{label}</strong>
                    </p>
                    <p>Avg Daily Calories: {payload[0].payload.totalCalories.toLocaleString()}</p>
                    <div style={{ color: '#24C581' }}>
                        Avg Daily Protein: {payload[0].payload.proteinGrams.toFixed(1)}g
                    </div>
                    <div style={{ color: '#25ABFF', margin:'5px 0px' }}>
                        Avg Daily Carbs: {payload[0].payload.carbsGrams.toFixed(1)}g
                    </div>
                    <div style={{ color: '#FF802C' }}>
                        Avg Daily Fat: {payload[0].payload.fatGrams.toFixed(1)}g
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderChart = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>Loading data...</h2>
                </div>
            );
        }

        if (weeklyData.length === 0) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>No data available for the selected period.</h2>
                    <p>Please track your meals to see weekly reports.</p>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={weeklyData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="proteinGrams"
                        name="Protein (g)"
                        stackId="1"
                        stroke="#24C581"
                        fill="#24C581"
                    />
                    <Area
                        type="monotone"
                        dataKey="carbsGrams"
                        name="Carbs (g)"
                        stackId="1"
                        stroke="#25ABFF"
                        fill="#25ABFF"
                    />
                    <Area
                        type="monotone"
                        dataKey="fatGrams"
                        name="Fat (g)"
                        stackId="1"
                        stroke="#FF802C"
                        fill="#FF802C"
                    />
                    <Brush
                        dataKey="weekLabel"
                        height={30}
                        stroke="#2D7933"
                        startIndex={Math.max(weeklyData.length - 4, 0)}
                        endIndex={weeklyData.length - 1}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div
            className="weekly-report-page"
            style={{
                maxWidth: '800px',
                margin: '0 auto',
                marginLeft: '-10px',
                width: '100%',
            }}
        >
            {renderChart()}
            <div style={{ marginTop: '10px', fontSize: '12px', color: 'grey', marginLeft: '15px', textAlign: 'center' }}>
                Note: it's better to skip whole days than to log partial days
            </div>
        </div>
    );
};

export default CaloriesOverTimeGraph;