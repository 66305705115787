import './planning_page.css'
import React from "react";
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip'; // Import Chip component
import { Capacitor } from '@capacitor/core';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import PlanRecipes from 'components/shared/plan_recipes/plan_recipes';
import RecipeCookingPlanner from 'components/shared/recipe_cooking_planner/recipe_cooking_planner';
import OrderIngredients from 'components/shared/order_ingredients/order_ingredients';
import AIPlanning from 'components/shared/ai_planning/ai_planning';
import ChatWithAI from 'components/shared/chat_with_ai/chat_with_ai';
import Chat from 'components/shared/chat_with_ai/chat_with_ai';

const PlanningPage = () => {
    const loadUsers = React.useContext(CartContext).loadUsers;
    const isNewlyWrittenUser = React.useContext(AuthContext).isNewlyWrittenUser;
    const [tabValue, setTabValue] = React.useState(0);
    const [showWeeklyPlanChip, setShowWeeklyPlanChip] = React.useState(false)
    const [showIngredientsChip, setShowIngredientsChip] = React.useState(false)

    const handleTabChange = (event, newValue) => {
        if (newValue === 1) {
            setShowWeeklyPlanChip(false)
        }
        if (newValue === 2) {
            setShowIngredientsChip(false)
        }
        setTabValue(newValue);
    };

    React.useEffect(() => {
        loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewlyWrittenUser]);

    return (
        <>
            <div className="planning-page" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="planning-page-top">
                    {Capacitor.getPlatform() !== "web" && <MobileSettingsButton isPlanning={true} />}

                    <Tabs TabIndicatorProps={{ style: { backgroundColor: "#2D7933" } }} style={{ width: 'calc(100% - 38px)', maxWidth: '400px', marginLeft:'40px'}} value={tabValue} onChange={handleTabChange}>
                        <Tab style={{ minWidth:'60px', width: '70px', marginLeft:'8px' }} label="Recipes" />
                        {/* <Tab style={{ width: '20%' }} label="AI Planning" /> */}
                        <Tab style={{ width: '120px', position: 'relative' }} label={
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                Weekly Plan
                                {showWeeklyPlanChip && <Chip
                                    label=""
                                    size="small"
                                    style={{
                                        position: 'absolute',
                                        top: -6,
                                        right: -7,
                                        backgroundColor: 'red',
                                        color: 'white',
                                        height: 10,
                                        minWidth: 10,
                                        width: 10,
                                        fontSize: '5px',
                                        padding: 0,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        opacity: '1'
                                    }}
                                />}
                            </div>
                        } />
                        <Tab style={{ width: '130px', position: 'relative' }} label={
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                Get Ingredients
                                {showIngredientsChip && <Chip
                                    label=""
                                    size="small"
                                    style={{
                                        position: 'absolute',
                                        top: -6,
                                        right: -7,
                                        backgroundColor: 'red',
                                        color: 'white',
                                        height: 10,
                                        minWidth: 10,
                                        width: 10,
                                        fontSize: '5px',
                                        padding: 0,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 300,
                                        overflow: 'visible'
                                    }}
                                />
                                }
                            </div>
                        } />
                    </Tabs>
                </div>
                {tabValue === 0 && <PlanRecipes setShowIngredientsChip={setShowIngredientsChip} setShowWeeklyPlanChip={setShowWeeklyPlanChip} />}
                {/* {tabValue === 1 && <Chat setTabValue={setTabValue} />} */}
                {tabValue === 1 && <RecipeCookingPlanner />}
                {tabValue === 2 && <>
                    <OrderIngredients showWarningImmediately={true} />
                </>}
            </div>
        </>
    );
}

export default PlanningPage;
