import './mobile_settings_button.css';

import React from 'react';


import MenuIcon from '@material-ui/icons/Menu';

import SettingsSidebar from '../settings_sidebar/settings_sidebar';



const MobileSettingsButton = (props) => {
  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false)
  // const foodVendor = React.useContext(UserContext).userObject.foodVendor;

  function handleSettingsClick() {
    setSettingsDialogOpen(true)
  }

  const handleSettingsDialogExitClick = () => {
    setSettingsDialogOpen(false)
  }

  return (
    <><div style={{ textAlign: 'center' }}>
      <button className="mobile-settings-button" style={props.isPlanning && { top: '13px', color: '#222222' }} onClick={handleSettingsClick}>
        <MenuIcon />
      </button>
    </div>
      <SettingsSidebar open={settingsDialogOpen} close={handleSettingsDialogExitClick} >
      </SettingsSidebar>





    </>
  )
}

export default MobileSettingsButton;