import React, { useState, useEffect } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { UserContext } from 'contexts/user_context';
import DateCircle from '../date_circle/date_circle';
import StreakDisplay from '../streak_display/streak_display';

const CalendarHeader = () => {
    const userObject = React.useContext(UserContext).userObject;
    const setUserObjectNoSave = React.useContext(UserContext).setUserObjectNoSave;

    const todayPosition = 30; // Assume position 30 is today
    const maxRange = 54; // Maximum range supported (30 days ahead and 30 days ago)
    const minPosition = 1; // Minimum range supported

    const [selectedPosition, setSelectedPosition] = useState(todayPosition);
    const [startPosition, setStartPosition] = useState(todayPosition - 3); // Start 3 days before today to center it

    // Center the selected date when it changes
    useEffect(() => {
        const initialPosition = userObject.calendarPosition || todayPosition;
        setSelectedPosition(initialPosition);
        centerDate(initialPosition);
    }, [userObject.calendarPosition]);

    // Function to calculate the actual date from the calendar position
    const calculateDateFromPosition = (position) => {
        const today = new Date();
        const difference = position - todayPosition;
        const newDate = new Date(today);
        newDate.setDate(today.getDate() + difference);
        return newDate;
    };

    function determineDay() {
        let date = calculateDateFromPosition(userObject.calendarPosition);
        return [date.getDate(), date.toLocaleDateString('en-US', { weekday: 'long' }), date.getMonth() + 1];
    }

    // Function to change calendar position based on user clicks
    const handleDateChange = (newPosition) => {
        moveCalendarPosition(newPosition - userObject.calendarPosition);
    };

    // Function to move the calendar position
    const moveCalendarPosition = (amount) => {
        let calendarPosition = userObject.calendarPosition || todayPosition;
        setUserObjectNoSave({
            ...userObject,
            calendarPosition: calendarPosition + amount,
            calendarPositionModified: true,
        });
    };

    // Function to center the selected date in the visible range or align to the left if at minPosition
    const centerDate = (newPosition) => {
        const centerOffset = 3; // Number of days to center the selected date
        let newStartPosition = newPosition - centerOffset;

        // Ensure the new start position is within range
        if (newStartPosition < minPosition) {
            newStartPosition = minPosition; // Align to the left if we reach the minimum
        } else if (newStartPosition > maxRange - 6) {
            newStartPosition = maxRange - 6; // Align to the right if we reach the maximum
        }
    };

    // Function to shift the visible range left or right
    const shiftVisibleRange = (direction) => {
        const shiftAmount = 3; // Number of days to shift by

        if (direction === 'next') {
            setStartPosition((prev) => {
                const newStartPosition = Math.min(prev + shiftAmount, maxRange - 6);
                return newStartPosition;
            });
        } else if (direction === 'prev') {
            setStartPosition((prev) => {
                // Ensure the new start position does not go below minPosition
                const newStartPosition = Math.max(prev - shiftAmount, minPosition);
                return newStartPosition;
            });
        }
    };

    // Function to render date circles
    const renderDateCircles = () => {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const currentPosition = startPosition + i;
            const date = calculateDateFromPosition(currentPosition);
            days.push(
                <DateCircle
                    key={i}
                    date={date} // Pass the actual Date object
                    selected={selectedPosition === currentPosition}
                    onClick={() => {
                        handleDateChange(currentPosition);
                        centerDate(currentPosition);
                    }}
                />
            );
        }
        return days;
    };

    let [date, day, month] = determineDay();

    return (
        <div className="calendar-header">
            <div
                className="calendar-header-top"
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto 1fr',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '600px',
                }}
            >
                <div style={{ gridColumn: '2 / 3', textAlign: 'center' }}>
                    {day} {month}/{date}
                </div>
                <div style={{ gridColumn: '3 / 4', justifySelf: 'end' }}>
                    <StreakDisplay />
                </div>

            </div>

            <div style={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '600px' }}>
                <div onClick={() => shiftVisibleRange('prev')}>
                    <NavigateBeforeIcon />
                </div>
                <div style={{ display: 'flex', overflowX: 'auto', flexGrow: 1, justifyContent: 'center', width: '90%', margin: '5px 0px 10px 0px' }}>
                    {renderDateCircles()}
                </div>
                <div onClick={() => shiftVisibleRange('next')}>
                    <NavigateNextIcon />
                </div>
            </div>
        </div>
    );
};

export default CalendarHeader;
