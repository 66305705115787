
import { LocalNotifications } from '@capacitor/local-notifications';

export const scheduleDailyReminder = async (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const now = new Date();

  let scheduledTime = new Date();
  scheduledTime.setHours(hours, minutes, 0, 0);

  if (scheduledTime <= now) {
    // Schedule for the next day
    scheduledTime.setDate(scheduledTime.getDate() + 1);
  }

  await LocalNotifications.schedule({
    notifications: [
      {
        id: 1,
        title: 'Time to track!',
        body: "Don't forget to log your meals/exercise today.",
        schedule: { at: scheduledTime, repeats: false },
        sound: null,
      },
    ],
  });
};
