import React from 'react';
import { UserContext } from 'contexts/user_context';
import BasicInfoStep from './steps/basic_info_step';
import ActivityLifestyleStep from './steps/activity_lifestyle_step';
import GoalSettingStep from './steps/goal_setting_step';
import { getDateMonthYear } from 'helpers/date_helpers';

const CalorieCalculator = (props) => {
    const userContext = React.useContext(UserContext);
    const { userObject, setUserObject, getCurrentWeight, getCurrentBodyFat } = userContext;
    const [step, setStep] = React.useState(1);

    // Initialize form data from userObject
    const [formData, setFormData] = React.useState({
        // Basic Info
        sex: userObject.sex || '',
        age: userObject.age || '',
        height: userObject.height || '',
        heightUnit: userObject.heightUnit || 'ft',
        heightFeet: userObject.heightFeet || '',
        heightInches: userObject.heightInches || '',
        weight: userObject.weight || {},
        bodyFat: userObject.bodyFat || {},
        currentBodyFat: parseFloat(getCurrentBodyFat()) || '',

        // Activity Levels
        activityLevel: userObject.activityMultiplier || 1.2,
        cardioActivity: userObject.cardioModifier || 1.0,
        muscleBuildingActivity: userObject.muscleBuildingModifier || 1.0,

        // Goals
        goalWeight: userObject.weightGoal?.value || '',
        goalBodyFat: userObject.bodyFatGoal || '',
        dailyCalories: userObject.calorieTarget || '',
        macroDistribution: userObject.macroDistribution || { protein: 30, carbs: 40, fat: 30 }
    });


    const handleUpdateFormData = (updates) => {
        setFormData(prev => ({
            ...prev,
            ...updates
        }));
    };

    const handleNext = () => {
        if (step === 1) {
            // Save basic info to userObject when moving from step 1
            const dateKey = getDateMonthYear(new Date());
            const updatedUserObject = {
                ...userObject,
                sex: formData.sex,
                age: formData.age,
                height: formData.height,
                heightUnit: formData.heightUnit,
                heightFeet: formData.heightFeet,
                heightInches: formData.heightInches,
                weight: formData.weight
            };
            // Only add bodyFat if it exists
            if (formData.currentBodyFat !== '' && formData.currentBodyFat !== undefined) {
                updatedUserObject.bodyFat = {
                    ...userObject.bodyFat,
                    [dateKey]: parseFloat(formData.currentBodyFat)
                };
            }

            setUserObject(updatedUserObject);
        }

        if (step < 3) setStep(step + 1);
        else handleSubmit();
    };

    const handleBack = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleSubmit = (dataPoints) => {
        setUserObject({
            ...userObject,
            activityMultiplier: formData.activityLevel,
            cardioModifier: formData.cardioActivity,
            muscleBuildingModifier: formData.muscleBuildingActivity,
            calories: formData.dailyCalories,
            proteins: Math.round(formData.macroDistribution.protein * formData.dailyCalories / 400),
            fats: Math.round(formData.macroDistribution.fat * formData.dailyCalories  / 900),
            carbs: Math.round(formData.macroDistribution.carbs * formData.dailyCalories / 400),
            macroDistribution: formData.macroDistribution,
            weightProjection: dataPoints
        });
        props.handleExitClick();
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <BasicInfoStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        onNext={handleNext}
                    />
                );
            case 2:
                return (
                    <ActivityLifestyleStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        onNext={handleNext}
                        onBack={handleBack}
                    />
                );
            case 3:
                return (
                    <GoalSettingStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        onSubmit={handleSubmit}
                        onBack={handleBack}
                        userObject={userObject}
                        setUserObject={setUserObject}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="calorie-calculator-form">
            {renderStep()}
        </div>
    );
};

export default CalorieCalculator;