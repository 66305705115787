export const groupDataByWeek = (data) => {
  const weeks = {};

  data.forEach((entry) => {
      const date = new Date(entry.date);
      const weekNumber = getISOWeek(date);
      const year = date.getFullYear();
      const weekKey = `${year}-W${weekNumber.toString().padStart(2, '0')}`;
      
      if (!weeks[weekKey]) {
          weeks[weekKey] = [];
      }
      weeks[weekKey].push(entry);
  });

  return weeks;
};

export const getISOWeek = (date) => {
  const targetDate = new Date(date);
  targetDate.setHours(0, 0, 0, 0);
  targetDate.setDate(targetDate.getDate() + 3 - (targetDate.getDay() + 6) % 7);
  const yearStart = new Date(targetDate.getFullYear(), 0, 4);
  return Math.ceil((((targetDate - yearStart) / 86400000) + 1) / 7);
};

export const aggregateWeeklyData = (groupedData) => {
  return Object.keys(groupedData).map((week) => {
      const weekData = groupedData[week];
      
      // Group entries by day to handle multiple meals per day
      const dailyTotals = {};
      weekData.forEach((entry) => {
          const dateStr = new Date(entry.date).toISOString().split('T')[0];
          
          if (!dailyTotals[dateStr]) {
              dailyTotals[dateStr] = {
                  totalCalories: 0,
                  proteinGrams: 0,
                  carbsGrams: 0,
                  fatGrams: 0,
                  mealCount: 0
              };
          }
          
          dailyTotals[dateStr].totalCalories += entry.totalCalories;
          dailyTotals[dateStr].proteinGrams += entry.proteinGrams;
          dailyTotals[dateStr].carbsGrams += entry.carbsGrams;
          dailyTotals[dateStr].fatGrams += entry.fatGrams;
          dailyTotals[dateStr].mealCount++;
      });
      
      // Calculate number of unique days with data
      const numDaysWithData = Object.keys(dailyTotals).length;
      
      // Sum up daily totals
      const totals = Object.values(dailyTotals).reduce((acc, day) => ({
          totalCalories: acc.totalCalories + day.totalCalories,
          proteinGrams: acc.proteinGrams + day.proteinGrams,
          carbsGrams: acc.carbsGrams + day.carbsGrams,
          fatGrams: acc.fatGrams + day.fatGrams
      }), {
          totalCalories: 0,
          proteinGrams: 0,
          carbsGrams: 0,
          fatGrams: 0
      });
      
      return {
          week,
          totalCalories: Math.round(totals.totalCalories / numDaysWithData),
          proteinGrams: Number((totals.proteinGrams / numDaysWithData).toFixed(1)),
          carbsGrams: Number((totals.carbsGrams / numDaysWithData).toFixed(1)),
          fatGrams: Number((totals.fatGrams / numDaysWithData).toFixed(1))
      };
  });
};