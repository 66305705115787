//React, router
import React, { useContext } from 'react';

//context
import { UserContext } from 'contexts/user_context';
import { Dialog } from '@material-ui/core';
import CalorieCalculator from '../calorie_calculator/calorie_calculator';

const CalorieMealSuggestion = (props) => {

    const [calculatorDialogOpen, setCalculatorDialogOpen] = React.useState(false)

    function handleOpenCalculatorClick() {
        setCalculatorDialogOpen(true)
    }

    function handleCalculatorDialogExitClick() {
        setCalculatorDialogOpen(false)
    }


    if (props.disableRecommendation) {

        return (
            <>
                <div className="calorie-meal-suggestion-calculator-message" style={{ justifySelf: 'auto' }}>Want to recalc your macros? <button className="calorie-meal-suggestion-message-button" variant="outlined" onClick={handleOpenCalculatorClick}>Calculate</button></div>
                <Dialog open={calculatorDialogOpen} onClose={handleCalculatorDialogExitClick} >
                    <CalorieCalculator handleExitClick={handleCalculatorDialogExitClick} />
                </Dialog>
            </>
        )
    }
    else {
        return <></>
    }
}
export default CalorieMealSuggestion