import React from 'react';
import {
    TextField,
    InputAdornment,
    Typography,
    Slider,
    withStyles,
} from '@material-ui/core';
import { calculateProjections } from '../projection_calculations';
import WeightProjectionGraph from '../weight_projection_graph';
import MacroSlider from '../macro_slider';
import './goal_setting_step.css';
import { UserContext } from 'contexts/user_context';

const GreenSlider = withStyles({
    root: {
        color: 'rgb(76, 184, 133)',
    },
    valueLabel: {
        '& *': {
            background: 'rgb(76, 184, 133)',
            color: 'white',
        },
    },
})(Slider);

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(76, 184, 133)',
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgb(76, 184, 133)',
        },
    },
})(TextField);

const GoalSettingStep = ({ formData, onUpdate, onSubmit, onBack, userObject, setUserObject }) => {
    const [errors, setErrors] = React.useState({});
    const [projections, setProjections] = React.useState(null);
    const [recommendations, setRecommendations] = React.useState(null);
    const [needsCalculation, setNeedsCalculation] = React.useState(!formData.goalWeight);
    const getCurrentWeight = React.useContext(UserContext).getCurrentWeight

    React.useEffect(() => {
        if (formData.goalWeight || formData.goalBodyFat) {
            setNeedsCalculation(true);
            setProjections(null);
            setRecommendations(null);
        }
    }, [formData.goalWeight, formData.goalBodyFat]);


    const handleGoalUpdate = (updates) => {
        setNeedsCalculation(true);
        setProjections(null);
        setRecommendations(null);
        onUpdate(updates);
    };

    const getCalorieAdjustment = (weightDiff, currentWeight) => {
        const changePercentage = Math.abs(weightDiff) / currentWeight * 100;

        if (changePercentage < 1) {
            return 1.0; // Maintenance calories
        }

        if (weightDiff < 0) { // Weight loss
            if (changePercentage < 5) {
                return 0.90; // 10% deficit for small losses
            } else if (changePercentage < 10) {
                return 0.85; // 15% deficit for moderate losses
            } else {
                return 0.80; // 20% deficit for larger losses
            }
        } else { // Weight gain
            if (changePercentage < 5) {
                return 1.05; // 5% surplus for small gains
            } else if (changePercentage < 10) {
                return 1.08; // 8% surplus for moderate gains
            } else {
                return 1.10; // 10% surplus for larger gains
            }
        }
    };

    const calculateMacros = () => {
        if (!formData.goalWeight) return;

        const [currentWeight, weightUnit] = getCurrentWeight();
        if (!currentWeight) return;

        // Convert weight to kg for calculations
        const toKg = weightUnit === 'lbs' ? 0.453592 : 1;
        const weightKg = currentWeight * toKg;

        // Calculate height in cm
        const heightCm = formData.heightUnit === 'cm' ?
            formData.height :
            ((parseFloat(formData.heightFeet || 0) * 12) + parseFloat(formData.heightInches || 0)) * 2.54;

        // Calculate BMR using Mifflin-St Jeor
        let BMR;
        if (formData.sex === 'male') {
            BMR = 10 * weightKg + 6.25 * heightCm - 5 * formData.age + 5;
        } else {
            BMR = 10 * weightKg + 6.25 * heightCm - 5 * formData.age - 161;
        }

        // Calculate TDEE with proper activity multipliers
        const baseMultiplier = formData.activityLevel;
        const cardioMultiplier = formData.cardioActivity - 1;
        const strengthMultiplier = formData.muscleBuildingActivity - 1;

        // Base TDEE using lifestyle multiplier
        let TDEE = BMR * baseMultiplier;

        // Add exercise impacts
        const cardioImpact = cardioMultiplier * weightKg * 2.5 * 7;
        const strengthImpact = strengthMultiplier * weightKg * 2.75 * 7;
        TDEE += (cardioImpact + strengthImpact) / 7;

        // Calculate target calories
        const weightDiff = formData.goalWeight - currentWeight;
        const isWeightLoss = weightDiff < 0;
        const adjustmentFactor = getCalorieAdjustment(weightDiff, currentWeight);
        const calculatedCalories = Math.round(TDEE * adjustmentFactor);

        // Set initial recommended values
        onUpdate({
            dailyCalories: calculatedCalories,
            macroDistribution: {
                protein: isWeightLoss ? 35 : 30,
                carbs: isWeightLoss ? 40 : 45,
                fat: isWeightLoss ? 25 : 25
            }
        });

        // Initial projections calculation
        updateProjections(calculatedCalories, {
            protein: isWeightLoss ? 35 : 30,
            carbs: isWeightLoss ? 40 : 45,
            fat: isWeightLoss ? 25 : 25
        });

        setNeedsCalculation(false);
    };

    const updateProjections = (calories = formData.dailyCalories, macros = formData.macroDistribution) => {
        const [currentWeight, weightUnit] = getCurrentWeight();

        const projectionResults = calculateProjections({
            currentWeight,
            goalWeight: formData.goalWeight,
            currentBodyFat: formData.currentBodyFat,
            goalBodyFat: formData.goalBodyFat,
            dailyCalories: calories,
            macroDistribution: macros,
            activityLevel: formData.activityLevel,
            cardioActivity: formData.cardioActivity,
            muscleBuildingActivity: formData.muscleBuildingActivity,
            userObject: {
                sex: formData.sex,
                age: formData.age,
                height: formData.height,
                heightUnit: formData.heightUnit,
                heightFeet: formData.heightFeet,
                heightInches: formData.heightInches,
                weightUnit,
            }
        });

        setProjections(projectionResults);
        generateRecommendations(projectionResults);
    };

    const generateRecommendations = (projectionData) => {
        if (!projectionData) return;

        const weightDiff = formData.goalWeight - getCurrentWeight()[0];
        const isWeightLoss = weightDiff < 0;

        setRecommendations({
            tips: generateTips(isWeightLoss)
        });
    };

    const generateTips = (isWeightLoss) => {
        let tips = [];
        if (isWeightLoss) {
            tips = [
                "Higher protein helps preserve muscle during weight loss",
                "Moderate carbs provide energy for workouts",
                "Adequate fats support hormone function"
            ];
        } else {
            tips = [
                "Increased carbs support muscle growth",
                "Moderate protein enables muscle repair",
                "Balanced fats aid nutrient absorption"
            ];
        }
        return tips;
    };

    const validateForm = () => {
        const newErrors = {};
        const [currentWeight] = getCurrentWeight();
        const weightDiff = Math.abs(formData.goalWeight - currentWeight);
        const maxWeeklyChange = formData.weightUnit === 'lbs' ? 2 : 0.9;

        if (!formData.goalWeight) {
            newErrors.goalWeight = 'Please enter your goal weight';
        } else if (weightDiff > (maxWeeklyChange * 52)) {
            newErrors.goalWeight = `This goal may be too ambitious for one year (max ${maxWeeklyChange} ${formData.weightUnit}/week)`;
        }

        if (formData.goalBodyFat) {
            const minBF = formData.sex === 'male' ? 5 : 12;
            if (formData.goalBodyFat < minBF || formData.goalBodyFat > 60) {
                newErrors.goalBodyFat = `Body fat should be between ${minBF}% and 60%`;
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;



        if (needsCalculation) {
      

            const updatedUserObject = {
                ...userObject,
                weightGoal: {
                    value: formData.goalWeight,
                    unit: getCurrentWeight()[1]
                }
            };
    
            // Only add bodyFat goal if it exists
            if (formData.goalBodyFat !== '' && formData.goalBodyFat !== undefined) {
                updatedUserObject.bodyFatGoal = parseFloat(formData.goalBodyFat);
            }
    
            setUserObject(updatedUserObject);

            calculateMacros();
        } else {
            onSubmit(projections.dataPoints);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="bt-goal-form">
            <div className="bt-goal-title">
                Set your <b style={{ color: 'rgb(76, 184, 133)' }}>goals</b>
            </div>

            <div className="bt-goal-section">
                <StyledTextField
                    label="Goal Weight"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.goalWeight}
                    onChange={(e) => handleGoalUpdate({ goalWeight: parseFloat(e.target.value) })}
                    error={!!errors.goalWeight}
                    helperText={errors.goalWeight}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{getCurrentWeight()[1]}</InputAdornment>,
                    }}
                />
            </div>

            <div className="bt-goal-section">
                <StyledTextField
                    label="Goal Body Fat % (optional)"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.goalBodyFat}
                    onChange={(e) => handleGoalUpdate({ goalBodyFat: parseFloat(e.target.value) })}
                    error={!!errors.goalBodyFat}
                    helperText={errors.goalBodyFat}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>

            {projections && !needsCalculation && (
                <>
                    <div className="bt-goal-section">
                        <Typography variant="subtitle1" gutterBottom>
                            Daily Caloric Intake
                        </Typography>
                        <div className="bt-goal-calories">
                            <GreenSlider
                                value={formData.dailyCalories}
                                onChange={(_, value) => {
                                    onUpdate({ dailyCalories: value });
                                    updateProjections(value);
                                }}
                                min={formData.sex === 'male' ? 1500 : 1200}
                                max={4000}
                                step={25}
                                valueLabelDisplay="on"
                            />
                        </div>
                    </div>

                    <div className="bt-goal-section">
                        <Typography variant="subtitle1" gutterBottom>
                            Recommended Macro Split
                        </Typography>
                        {recommendations && (
                            <div className="bt-goal-macro-tips">
                                {recommendations.tips.map((tip, index) => (
                                    <div key={index} className="bt-goal-macro-tip">
                                        {tip}
                                    </div>
                                ))}
                            </div>
                        )}
                        <MacroSlider
                            macroDistribution={formData.macroDistribution}
                            setMacroDistribution={(newMacros) => {
                                onUpdate({ macroDistribution: newMacros });
                                updateProjections(formData.dailyCalories, newMacros);
                            }}
                            dailyCalories={formData.dailyCalories}
                        />
                    </div>

                    {projections.warning && projections.warning.length > 0 && (
                        <div className="bt-goal-warning">
                            <div className="bt-goal-warning-title">
                                Important Considerations
                            </div>
                            <div className="bt-goal-warning-content">
                                {Array.isArray(projections.warning) ?
                                    projections.warning.map((warning, index) => (
                                        <div key={index} className="bt-goal-warning-item">
                                            {warning}
                                        </div>
                                    ))
                                    :
                                    <div className="bt-goal-warning-item">
                                        {projections.warning}
                                    </div>
                                }
                            </div>
                        </div>
                    )}

                    {projections.recommendations && (
                        <div className="bt-goal-recommendations">
                            <div className="bt-goal-recommendations-title">
                                Recommendations to Optimize Your Results
                            </div>
                            <div className="bt-goal-recommendations-content">
                                {projections.recommendations.split('\n\n').map((recommendation, index) => (
                                    <div key={index} className="bt-goal-recommendation-item">
                                        {recommendation}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="bt-goal-projection">
                        <Typography variant="h6" gutterBottom style={{ color: '#888888', textAlign: 'center', marginBottom: '10px' }}>
                            Weight & Body Fat Projection
                        </Typography>
                        <Typography  gutterBottom style={{ color: '#888888', textAlign: 'center', marginBottom: '20px', fontSize:'12.5px' }}>
                            Change calories/macros to see your projection change. Note this is an approximation and our AI consultant will help you modify your targets based on your tracking data.
                        </Typography>
                        <WeightProjectionGraph
                            data={projections.dataPoints}
                            weightUnit={getCurrentWeight()[1]}
                        />
                    </div>
                </>
            )}

            <div className="bt-goal-buttons">
                <button type="button" onClick={onBack} className="bt-goal-back-button">
                    Back
                </button>
                <button
                    type="submit"
                    className="bt-goal-submit-button"
                >
                    {needsCalculation ? 'Calculate Plan' : 'Save Plan'}
                </button>
            </div>
        </form>
    );
};

export default GoalSettingStep;