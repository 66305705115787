// NotificationScheduler.js

import { useEffect, useContext } from 'react';
import { LocalNotifications } from '@capacitor/local-notifications';
import { CartContext } from 'contexts/cart_context';
import { scheduleDailyReminder } from 'helpers/notification_helpers';

const NotificationScheduler = ({ wantsReminders, reminderTime }) => {
  const { cartObject } = useContext(CartContext);

  useEffect(() => {
    const setupNotifications = async () => {
      // Cancel existing notification
      await LocalNotifications.cancel({ notifications: [{ id: 1 }] });

      if (wantsReminders) {
        // Check if user has logged meals today
        const today = new Date().toDateString();
        const mealsToday = cartObject.some(meal => {
          const mealDate = new Date(meal.calendarDate).toDateString();
          return mealDate === today;
        });

        if (!mealsToday) {
          // Request permission
          const permission = await LocalNotifications.requestPermissions();
          if (permission.display === 'granted') {
            // Schedule the daily reminder
            await scheduleDailyReminder(reminderTime);
          } else {
            alert('Notifications permission denied. Please enable it in your device settings.');
          }
        }
      }
    };

    setupNotifications();
  }, [wantsReminders, reminderTime, cartObject]);

  return null;
};

export default NotificationScheduler;
