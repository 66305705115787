import React from "react";
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { config } from 'Constants';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { pink } from '@material-ui/core/colors';
import {
    Switch,
    withStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Typography
} from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { ToggleButtonGroup } from '@material-ui/lab';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import _ from "lodash";
import { ToggleButton } from '@material-ui/lab';

const PurpleSwitch = withStyles({
    switchBase: {
        color: pink[100],
        '&$checked': {
            color: pink[300],
        },
        '&$checked + $track': {
            backgroundColor: pink[300],
        },
    },
    checked: {},
    track: {
        backgroundColor: pink[100],
    },
})(Switch);

// Create a custom theme with the primary color
const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(76, 184, 133)',
        },
    },
});

// Create a custom styled ToggleButton

const StyledToggleButton = withStyles({
    root: {
        '&$selected': {
            backgroundColor: 'rgba(76, 184, 133, 0.9)',
            color: 'rgba(255, 255, 255)',
            '&:hover': {
                backgroundColor: 'rgba(76, 184, 133, 0.9)',
            },
        },
    },
    selected: {},
})(ToggleButton);

const CalendarAddCustomMeal = (props) => {
    const addMealToColumn = React.useContext(CartContext).addOrMoveMealToColumn;
    const addFavoriteCustomLog = React.useContext(CartContext).addFavoriteCustomLog;
    const userObject = React.useContext(UserContext).userObject;
    const getCurrentWeight = React.useContext(UserContext).getCurrentWeight;

    const [mealObject, setMealObject] = React.useState(
        props.calendarCategory
            ? { ...config.emptyRecipe, calendarCategory: props.calendarCategory }
            : { ...config.emptyRecipe, calendarCategory: "breakfast" }
    );
    const [addingToFavorites, setAddingToFavorites] = React.useState(true);
    const [knowsCalories, setKnowsCalories] = React.useState(true);
    const [mealSize, setMealSize] = React.useState('moderate'); // Set default value
    const [mealComposition, setMealComposition] = React.useState('A'); // Set default value
    const [numberOfDrinks, setNumberOfDrinks] = React.useState(0);
    const [dessert, setDessert] = React.useState(false);

    const handleTextChange = (e) => {
        setMealObject({ ...mealObject, [e.target.name]: e.target.value })
    }

    const handleCalorieChange = (e) => {
        let mealObjectClone = _.cloneDeep(mealObject)
        for (let mealNutrient of mealObjectClone.nutrition.nutrients) {
            if (mealNutrient.name === "Calories") {
                mealNutrient['amount'] = e.target.value
            }
        }
        setMealObject(mealObjectClone)
    }

    const handleMacroChange = (e) => {
        let calculatedCalories;

        if (e.target.name === "Fat") {
            calculatedCalories = e.target.value * 9 + getMealNutrientAmount(mealObject, "Carbohydrates") * 4 + getMealNutrientAmount(mealObject, "Protein") * 4
        }

        if (e.target.name === "Carbohydrates") {
            calculatedCalories = e.target.value * 4 + getMealNutrientAmount(mealObject, "Fat") * 9 + getMealNutrientAmount(mealObject, "Protein") * 4
        }

        if (e.target.name === "Protein") {
            calculatedCalories = e.target.value * 4 + getMealNutrientAmount(mealObject, "Fat") * 9 + getMealNutrientAmount(mealObject, "Carbohydrates") * 4
        }

        let mealObjectClone = _.cloneDeep(mealObject)
        for (let mealNutrient of mealObjectClone.nutrition.nutrients) {
            if (mealNutrient.name === e.target.name) {
                mealNutrient['amount'] = e.target.value
            }
            if (mealNutrient.name === "Calories") {
                mealNutrient['amount'] = calculatedCalories
            }
        }
        setMealObject(mealObjectClone)
    }

    const handleSubmit = (e) => {
        e.preventDefault();


        if (!knowsCalories) {

            // Perform estimation

            let weight;
            const weightObject = getCurrentWeight(); // in kg
            if (typeof weightObject === 'number') {
                weight = weightObject / 2.2
            }

            else if (weightObject[1] === "lbs") {
                weight = weightObject[0] / 2.2
            }
            else {
                weight = weightObject[0] === '' ? 70 : weightObject[0]
            }

            const sex = userObject.sex ? userObject.sex : 'female';
            if (!weight || !sex) {
                alert('Please update your profile with your weight and sex to use the estimation feature.');
                return;
            }

            if (!mealSize || !mealComposition) {
                alert('Please answer all the questions.');
                return;
            }

            const CF = sex === 'male' ? 10 : 8;
            const BC = weight * CF;

            let MSF = mealSize === 'light' ? 0.75 : mealSize === 'moderate' ? 1.0 : 1.25;
            let adjustedBaseCalories = BC * MSF;
            const alcoholCalories = numberOfDrinks * 150;
            const dessertCalories = dessert ? 300 : 0;
            const totalCalories = adjustedBaseCalories + alcoholCalories + dessertCalories;

            const mealCompositionMacros = {
                'A': { protein: 30, carbs: 40, fat: 30 },
                'B': { protein: 40, carbs: 20, fat: 40 },
                'C': { protein: 35, carbs: 45, fat: 20 },
                'D': { protein: 14, carbs: 43, fat: 43 },
                'E': { protein: 60, carbs: 20, fat: 20 },
                'F': { protein: 20, carbs: 60, fat: 20 },
                'G': { protein: 15, carbs: 25, fat: 60 },
            };

            const macrosPercentages = mealCompositionMacros[mealComposition];

            const gramsProtein = (totalCalories * macrosPercentages.protein / 100) / 4;
            const gramsCarbs = (totalCalories * macrosPercentages.carbs / 100) / 4;
            const gramsFat = (totalCalories * macrosPercentages.fat / 100) / 9;

            let mealObjectClone = _.cloneDeep({ ...mealObject, name: 'Quick Log Meal', description: 'this meal was created via quick log and therefore is not as precise in terms of calories/macros and has no micronutrient data' });
            for (let mealNutrient of mealObjectClone.nutrition.nutrients) {
                if (mealNutrient.name === "Calories") {
                    mealNutrient['amount'] = totalCalories;
                }
                if (mealNutrient.name === "Protein") {
                    mealNutrient['amount'] = gramsProtein;
                }
                if (mealNutrient.name === "Carbohydrates") {
                    mealNutrient['amount'] = gramsCarbs;
                }
                if (mealNutrient.name === "Fat") {
                    mealNutrient['amount'] = gramsFat;
                }
            }
            mealObjectClone = { ...mealObjectClone, areNutrientsManual: true }

            addMealToColumn(props.calendarDate, mealObjectClone, mealObjectClone['calendarCategory']);

            props.handleExitClick();

        } else {
            if (!mealObject.name) {
                alert("Please enter a meal name.");
                return;
            }

            // Existing code
            let mealObjectClone = { ...mealObject, areNutrientsManual: true }
            addMealToColumn(props.calendarDate, mealObjectClone, mealObject['calendarCategory'])
            if (addingToFavorites) {
                addFavoriteCustomLog(mealObjectClone)
            }
            props.handleExitClick()
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <div className="custom-meal-form" style={{ textAlign: 'center', width: 'calc(100vw - 20px)', maxWidth: '500px' }}>

                <FormControl component="fieldset">
                    <div style={{ fontSize: '15px', color: '#6b6b6b' }} component="legend">Do you know the meal calories/macros?</div>
                    <RadioGroup style={{ textAlign: 'center', margin: '0px auto' }} row aria-label="knowsCalories" name="knowsCalories" value={knowsCalories ? 'yes' : 'no'} onChange={(e) => setKnowsCalories(e.target.value === 'yes')}>
                        <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                    </RadioGroup>
                </FormControl>
                <form onSubmit={handleSubmit}>

                    {knowsCalories ? (
                        <>
                            {/* Existing form fields */}
                            {/* Protein */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                                <TextField className="calorie-box-text-field-medium protein" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }}
                                    type="number" variant="filled" label="" name='Protein' value={getMealNutrientAmount(mealObject, "Protein") === 0 ? "" : getMealNutrientAmount(mealObject, "Protein")} onChange={handleMacroChange}></TextField>
                                <span className="calorie-box-label-medium protein-background">Protein</span></div>

                            {/* Carbs */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                                <TextField className="calorie-box-text-field-medium carbs" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }}
                                    type="number" variant="filled" label="" name='Carbohydrates' value={getMealNutrientAmount(mealObject, "Carbohydrates") === 0 ? "" : getMealNutrientAmount(mealObject, "Carbohydrates")} onChange={handleMacroChange}></TextField>
                                <span className="calorie-box-label-medium carbs-background">Carbs</span></div>

                            {/* Fat */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                                <TextField className="calorie-box-text-field-medium fat" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }}
                                    type="number" variant="filled" label="" name='Fat' value={getMealNutrientAmount(mealObject, "Fat") === 0 ? "" : getMealNutrientAmount(mealObject, "Fat")} onChange={handleMacroChange}></TextField>
                                <span className="calorie-box-label-medium fat-background">Fat</span></div>

                            {/* Calories */}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                                <TextField className="calorie-box-text-field-medium calories" variant="filled" type="number" name='Calories'
                                    value={getMealNutrientAmount(mealObject, "Calories") === 0 ? "" : getMealNutrientAmount(mealObject, "Calories")} onChange={handleCalorieChange}></TextField>
                                <span className="calorie-box-label-medium calories-background">Calories</span></div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0px' }}>
                                <TextField className="calorie-box-text-field-wide" style={{ width: '150px !important' }} type="text" variant="filled" required={true} label="" name='name' value={mealObject.name} onChange={handleTextChange}></TextField> <span className="calorie-box-label-narrow" style={{ background: '#000000' }}> Name</span>

                            </div>
                            <div> Save as favorite?
                                <PurpleSwitch style={{}} checked={addingToFavorites} onChange={() => { setAddingToFavorites(!addingToFavorites) }}></PurpleSwitch>
                                {addingToFavorites ? <FavoriteIcon style={{ marginBottom: '-7px', color: 'rgb(255 91 149)' }} /> : <FavoriteBorderIcon style={{ marginBottom: '-7px', color: 'grey' }} />}
                            </div>
                        </>
                    ) : (
                        <>
                            {/* Updated questions with styled ToggleButtons */}
                            <FormControl component="fieldset" style={{ margin: '10px 0' }}>
                                <div style={{ fontSize: '15px', color: '#6b6b6b', margin: '3px 0px' }} component="legend">How would you describe the amount you ate?</div>
                                <ToggleButtonGroup
                                    value={mealSize}
                                    exclusive
                                    onChange={(e, newValue) => { if (newValue !== null) setMealSize(newValue); }}
                                    aria-label="meal size"
                                    style={{ marginTop: '10px' }}
                                >
                                    <StyledToggleButton value="light" aria-label="light">
                                        Ate lightly
                                    </StyledToggleButton>
                                    <StyledToggleButton value="moderate" aria-label="moderate">
                                        Ate a moderate amount
                                    </StyledToggleButton>
                                    <StyledToggleButton value="heavy" aria-label="heavy">
                                        Ate heavily
                                    </StyledToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>

                            <FormControl component="fieldset" style={{ margin: '10px 0' }}>
                                <div style={{ fontSize: '15px', color: '#6b6b6b', margin: '3px 0px' }} component="legend">What food did you predominantly eat?</div>
                                <ToggleButtonGroup
                                    value={mealComposition}
                                    exclusive
                                    onChange={(e, newValue) => { if (newValue !== null) setMealComposition(newValue); }}
                                    aria-label="meal composition"
                                    orientation="vertical"
                                    style={{ marginTop: '10px' }}
                                >
                                    <StyledToggleButton value="A" aria-label="A">
                                        Mix of proteins, carbs, and fats
                                    </StyledToggleButton>
                                    <StyledToggleButton value="B" aria-label="B">
                                        Mostly proteins and fats
                                    </StyledToggleButton>
                                    <StyledToggleButton value="C" aria-label="C">
                                        Mostly proteins and carbs
                                    </StyledToggleButton>
                                    <StyledToggleButton value="D" aria-label="D">
                                        Mostly carbs and fats
                                    </StyledToggleButton>
                                    <StyledToggleButton value="E" aria-label="E">
                                        Mostly proteins (meats, eggs)
                                    </StyledToggleButton>
                                    <StyledToggleButton value="F" aria-label="F">
                                        Mostly carbs (bread, rice)
                                    </StyledToggleButton>
                                    <StyledToggleButton value="G" aria-label="G">
                                        Mostly fats (fried foods, creamy dishes)
                                    </StyledToggleButton>
                                 

                                </ToggleButtonGroup>
                            </FormControl>

                            <FormControl component="fieldset" style={{ margin: '10px 0' }}>
                                <div style={{ fontSize: '15px', color: '#6b6b6b', margin: '3px 0px' }} component="legend">Did you have dessert?</div>
                                <ToggleButtonGroup
                                    value={dessert ? 'yes' : 'no'}
                                    exclusive
                                    onChange={(e, newValue) => { if (newValue !== null) setDessert(newValue === 'yes'); }}
                                    aria-label="dessert"
                                    style={{ marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}
                                >
                                    <StyledToggleButton value="yes" aria-label="yes">
                                        Yes
                                    </StyledToggleButton>
                                    <StyledToggleButton value="no" aria-label="no">
                                        No
                                    </StyledToggleButton>
                                </ToggleButtonGroup>
                            </FormControl>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '10px 0' }}>
                                <IconButton color="primary" onClick={() => setNumberOfDrinks(Math.max(numberOfDrinks - 1, 0))}>
                                    <RemoveIcon />
                                </IconButton>
                                <div style={{ margin: '0 10px', color: 'grey' }}>
                                    Alcoholic beverages: {numberOfDrinks}
                                </div>
                                <IconButton color="primary" onClick={() => setNumberOfDrinks(numberOfDrinks + 1)}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                        </>
                    )}


                    <div className="custom-meal-form-submit-button">
                        <button type="submit" className="submit-button" style={{ background:'#4cb885', color:'white', marginBottom:'10px', padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold' }} variant="contained" color="primary">Add to calendar</button>
                    </div>
                </form>
            </div>
        </ThemeProvider>
    );
}

export default CalendarAddCustomMeal;

// Helper function
function getMealNutrientAmount(mealObject, nutrientName) {
    const nutrient = mealObject.nutrition.nutrients.find(n => n.name === nutrientName);
    return nutrient ? nutrient.amount : 0;
}
